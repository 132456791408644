import React from "react";
import { Card, IconCheckCircle, IconXCircle } from "@veneer/core";
import Link from "@material-ui/core/Link";
import { sendGaEvent } from "../helper/analytics";
import moment from "moment";
import AdditionalDeviceCardInfo from "./AdditionalDeviceCardInfo";

const DeviceCard = (props) => {
  const data = props?.data;
  let deviceStartMonth = "00";
  let deviceStartDate = "00";
  let deviceStartYear = "00";
  
  if (data.install_date) {
    const installDate = moment.utc(data.install_date, "YYYYMMDD").toDate();
    deviceStartMonth = installDate.getUTCMonth() + 1;
    deviceStartDate = installDate.getUTCDate();
    deviceStartYear = installDate.getFullYear();
  }

  const redirectNewTab = (id, type, modelName) => {
    if (modelName) {
      sendGaEvent("Device_selected", "Device", `Device selected for: ${modelName}`);
    }
    window.open(`/deviceDetails?id=${id}&type=${type}`);
  };

  return (
    <Card
      appearance="dropShadow"
      style={{
        width: "385px",
        padding: "20px 24px 24px 24px",
        margin: "24px",
      }}
      content={
        <div>
          <div className="subtitle-regular cardTitle">
            <span className="itsmK2 caption">{data.source_system?.value}</span>
            {(props.componentType !== "deviceList" || data.source_system?.value === "ORION") && (
              <span style={{ position: "relative", top: "2px" }}>
                Device Details
              </span>
            )}
            {props.componentType === "deviceList" && data.source_system?.value !== "ORION" && (
              <Link
                style={{
                  position: "relative",
                  top: "2px",
                  fontSize: "18px",
                  fontWeight: "700",
                  fontFamily: "Forma DJR UI, Arial, sans-serif",
                }}
                onClick={() => {
                  redirectNewTab(data.id, "address", data.model_name);
                }}
              >
                Device Details
              </Link>
            )}
          </div>
          <hr className="hr" />
          <div className="modelName">
            <h6
              style={{
                fontSize: "16px",
                lineHeight: "16px",
                textAlign: "center",
                margin: "20px 0 18px 0",
                fontWeight: "550"
              }}
            >
              {data.model_name}
            </h6>
          </div>
          <div className="cardContainerSpaceAround">
            <div className="StatusActiveOpenEntitled-Container">
              <label className="label-small">
                STATUS: {data.asset_status}
              </label>
              {data.asset_status === "Operational" && (
                <IconCheckCircle
                  filled
                  className="StatusActiveOpenEntitled-Check"
                  size={18}
                />
              )}
            </div>
            {props.componentType !== "deviceList" && (
              <div className="StatusActiveOpenEntitled-Container">
                <label
                  className="StatusActiveOpenEntitled-Label label-small overline-regular"
                  style={{ fontSize: "12px" }}
                >
                  ENTITLED
                </label>
                {data.is_entitled ? (
                  <IconCheckCircle
                    filled
                    className="StatusActiveOpenEntitled-Check"
                    size={18}
                  />
                ) : (
                  <IconXCircle
                    filled
                    className="StatusActiveOpenEntitled-CrossCheck"
                    size={18}
                  />
                )}
              </div>
            )}
          </div>

          <div>
            {data.source_system?.value !== "ITSM" ? (
              <div className="customerName">
                <h6
                  style={{
                    fontSize: "16px",
                    lineHeight: "18px",
                    textAlign: "center",
                    margin: "20px 0 10px 0",
                  }}
                >
                  {data.source_system?.value === "ORION" && data.fleet?.name}
                  {data.source_system?.value !== "ORION" && data.fleet?.end_customer?.name}
                </h6>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: "inline-block",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <div className="smallCardLabel smallCardLabel-Condensed label">
                    COG Name:
                    <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                      {data.opportunity?.customer_opportunity_group?.cog_name}
                    </label>
                  </div>
                </div>
                
                <div>
                  <div
                    style={{
                      display: "inline-block",
                      flexDirection: "row",
                    }}
                  >
                    <div className="smallCardLabel smallCardLabel-Condensed label">
                      Account Name:
                      <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                        {data.opportunity?.account?.name}
                      </label>
                    </div>
                  </div>
                    <div className="smallCardLabel smallCardLabel-Condensed label" style={{ marginBottom: "2px" }}>
                      Fleet Name:
                      <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                        {data.fleet?.name}
                      </label>
                    </div>
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "10px 0 3px",
            }}
          >
            <div className="smallCardLabel smallCardLabel-Condensed label">
              Serial No:
              <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                {data.serial_number}
              </label>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "2px 0 3px 0",
            }}
          >
            <div className="smallCardLabel smallCardLabel-Condensed label">
              Asset Number:
              <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                {data.asset_number}
              </label>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "0 0 3px",
            }}
          >
            <div className="smallCardLabel smallCardLabel-Condensed label">
              SKU:
              <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                {data.product_number}
              </label>
            </div>
          </div>

          <hr className="hr" />

          {props.componentType !== "deviceList" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "5px 0 3px",
              }}
            >
              <div className="smallCardLabel smallCardLabel-Condensed label">
                Installation Date:
                <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                  mm: {deviceStartMonth} / dd: {deviceStartDate} / yy: {deviceStartYear}
                </label>
              </div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "3px 0 3px",
            }}
          >
            <div className="smallCardLabel smallCardLabel-Condensed label">
              IP Address:
              <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                {data.ip_address}
              </label>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="smallCardLabel smallCardLabel-Condensed label">
              Host Name:
              <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                {data.host_name}
              </label>
            </div>
          </div>

          <hr className="hr" />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "5px 0 3px",
              
            }}
          >
            <div className="smallCardLabel smallCardLabel-Condensed label">
              Collection Method:
              <label className="smallCardLabelData smallCardLabelData-Condensed label-small">
                {data.collection_method}
              </label>
            </div>
          </div>

          <hr className="hr" />

          <div
            style={{
              display: "inline-block",
              flexDirection: "row",
              marginTop: "5px",
            }}
          >
            <div
              className="smallCardLabel smallCardLabel-Condensed label"
              style={{ lineHeight: "18px", height: "18px" }}
            >
              Supplies Routing ID/AMP ID:
              <label
                className="smallCardLabelData smallCardLabelData-Condensed label-small"
                style={{
                  lineHeight: "19px",
                  height: "19px",
                }}
              >
                {data.source_system?.value === 'ITSM' ? data.supplies_routing_id : data.fleet?.supplies_routing_id}
              </label>
            </div>
          </div>
          {props.componentType !== "deviceList" && (
            <AdditionalDeviceCardInfo
              data={data}
              props={props}
            />
          )}
        </div>
      }
    />
  );
};

export default DeviceCard;
